import React from "react";

export class Partners extends React.Component {
  render() {
    return (
      <div style={{ marginTop: "-10px" }}>
        <p style={{ marginBottom: 10 }}>Connect with talent <b>vetted</b> by humans.</p>
        <img
          alt="Airbnb"
          src="https://www.denverslocksmiths.com/wp-content/uploads/2018/04/airbnblogo.png"
          width="100px"
          position="absolute"
          style={{
            border: "0px solid  red",
            marginBottom: "40px",
            paddingRight: "5px"
          }}
        />
        <img
          alt="Lyft"
          src="https://cdn.freebiesupply.com/images/large/2x/lyft-logo-png-transparent.png"
          width="50px"
          style={{
            border: "0px solid  blue",
            marginBottom: "45px",
            paddingRight: "15px"
          }}
        />
        <img
          alt="Uber"
          src="https://www.freepnglogos.com/uploads/black-uber-logo-png-6.png"
          width="80px"
          position="absolute"
          style={{
            border: "0px solid  red",
            marginBottom: "57px",
            // paddingRight: "5px"
          }}
        />
        {/* <img
          alt="Tesla"
          src="https://www.freepnglogos.com/uploads/tesla-logo-png-27.png"
          width="40px"
          style={{
            border: "0px solid  blue",
            marginBottom: "55px",
            marginTop: "15px"
          }}
        /> */}
      </div>
    );
  }
}
